import ujs from '@rails/ujs'
import tl from 'turbolinks'

ujs.start()
tl.start()

$(document).on('turbolinks:load', function () {
  $(document).foundation()

  assignNavClasses()

  $('#play_search').on('submit', (e) => {
    e.preventDefault()

    // For some reason using $(this) was not working in this context.
    const $form = $('#play_search')
    const url = `${$form.attr('action')}?${$form.serialize()}`

    Turbolinks.visit(url)
  })

  let params = new URLSearchParams(location.search)
  $('.library-link').removeClass('expanded')
  if (params.get('play_tag')) {
    $(`.library-nav .${params.get('play_tag')}`).addClass('expanded')
  } else if (params.get('draft')) {
    $('.library-nav .draft').addClass('expanded')
  } else if (window.location.pathname === '/plays') {
    $('.library-link.all').addClass('expanded')
  }

  $('.edit-kit').on('click', function(e) {
    e.stopPropagation();
  })

  window.document.openCopySuccessModal = function () {
    $('#clipboard-copy-modal').foundation('open')
    setTimeout(() => {
      $('#clipboard-copy-modal').foundation('close')
    }, 5000)
  }

  $('.close-clipboard-copy-modal').click(function (e) {
    $('#clipboard-copy-modal').foundation('close')
  })

  $(window).click(function (e) {
    let modal = $('.modal')
    if (e.target == modal) {
      $('.modal').css('display', 'none')
    }
  })

  $('.play-submit').click(function (e) {
    e.preventDefault()
    const form = $('.play-editor')
    const newPlay = form.attr('action') === '/plays'

    if (e.currentTarget.id == 'publish') {
      $('input#direct_publish').val('publish')
    }

    // If we're creating a new play, bypass Turbolinks and do a full page
    // refresh so the nav updates. If we're editing an existing play, let UJS
    // handle the submission and transition with Turbolinks.
    if (newPlay) {
      form.submit()
    } else {
      form[0].dispatchEvent(new Event('submit', { bubbles: true }));
    }
  })

  $('.play-content').mouseup(function (e) {
    // Get the selection
    let selected = window.getSelection()
    let content = selected.getRangeAt(0)

    // Fill the selection
    let commentInput = document.getElementById('comment-input')
    commentInput.value = content

    // Show the popover
    let flyout = document.getElementById('play-comment-flyout')
    flyout.style.display = 'inherit'

    // Enable the comment submit button
    let submitButton = document.getElementById('play-comment-submit')
    submitButton.innerText = 'Post comment'
    submitButton.removeAttribute('disabled')
  })

  $('#play-comment-close').click(function (e) {
    // Remove the comment
    let commentInput = document.getElementById('comment-input')
    commentInput.value = ''

    // Hide the popover
    let flyout = document.getElementById('play-comment-flyout')
    flyout.style.display = 'none'

    // Enable the comment submit button
    let submitButton = document.getElementById('play-comment-submit')
    submitButton.innerText = 'Highlight Play text to comment'
    submitButton.setAttribute('disabled', 'disabled')
  })

  $('#library-tabs').tabs()
  $('#profile-tabs').tabs({
    activate: function () {
      $('ul.profile-pane-navigation li').removeClass('active')
    },
  })

  $('#profile-tabs-mobile').tabs({
    active: 1,
    activate: function () {
      $('ul.profile-pane-navigation li').removeClass('active')
    },
    heightStyle: 'auto'
  })

  $('.sortable').railsSortable()
  $('.select2').select2({ width: '100%', placeholder: 'Select an option' })

  const $nav = $('.primary-nav')
  $('.nav-toggle').on('click', (event) => {
    $nav.toggleClass('open')
    event.stopPropagation()
  })
  $(document).on('click', (event) => {
    const $target = $(event.target);

    if (!$target.closest($nav).length && $nav.hasClass('open')) {
      $nav.removeClass('open')
    }
  })
})

$(document).on('turbolinks:before-cache', () => {
  $('.select2-container').remove()
  $('.nav-toggle').off('click')
  $(document).off('click')
})

$(function() {
  $('.nav-tile .group').on('click', function (e) {
    if ($(e.target).is('a')) {
      return;
    }

    $(this).toggleClass('expanded')
    $(this).find('.content').toggleClass('expanded')
    $(this).find('.children').toggleClass('expanded')
  })

  $('#create-kit-menu a').on('click', () => {
    $('[data-toggle="create-kit-menu"]').trigger('click')
  })
})

function assignNavClasses() {
  const urlSegments = window.location.pathname.split('/')
  const kitsUrlIndex = urlSegments.indexOf('kits')
  const kitUrlId = Number(urlSegments.slice(kitsUrlIndex, kitsUrlIndex + 2)[1])
  const playsUrlIndex = urlSegments.indexOf('plays')
  const activePlayUrl = urlSegments.slice(playsUrlIndex, playsUrlIndex + 2).join('/')
  const activeLink = $(`a.nav-play-link[href$="${activePlayUrl}"]`)
  const activeKitId = !!Number(kitUrlId) ? $(`.nav-tile[data-kit-id="${kitUrlId}"]`) : activeLink.closest('.nav-tile').data('kit-id')

  // Update selected play
  $('.target.currentNav').removeClass('currentNav')
  activeLink.closest('.target').addClass('currentNav')

  // Update selected kit
  $('.nav-tile').each(function() {
    const kitId = $(this).data('kit-id')
    const active = kitId === kitUrlId || kitId === activeKitId

    $(this).find('.group').toggleClass('expanded', active)
    $(this).find('.content').toggleClass('expanded', active)
    $(this).find('.children').toggleClass('expanded', active)
  })
}
