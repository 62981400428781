import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

config.mutateApproach = 'sync'

import {
  faBars,
  faBooks,
  faBolt as faBoltRegular,
  faCheck,
  faClipboard,
  faEdit,
  faEllipsisV,
  faFolder,
  faHashtag,
  faIdCard,
  faLongArrowLeft,
  faPaperPlane,
  faPlus,
  faScroll,
  faSearch,
  faStickyNote,
  faPhone,
  faMobileAlt,
  faEnvelope,
  faAddressBook,
  faBook,
  faHeart,
  faReply,
  faRandom,
  faComments,
  faFlag,
  faFile,
  faLightbulb,
  faCompass,
  faHandshake,
  faCaretSquareRight,
  faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faBolt as faBoltLight,
  faClipboard as faClipboardLight,
  faCog,
  faCopy,
  faEdit as faEditLight,
  faRocket,
  faSave,
  faSignOutAlt,
  faStar,
  faTimes,
  faTrash,
  faUserAlt,
  faUsers,
  faUserEdit,
} from '@fortawesome/pro-light-svg-icons'

import {
  faBolt,
  faCheck as faCheckSolid,
  faCheckSquare,
  faComment,
  faEdit as faEditSolid,
  faGripVertical,
  faIdCard as faIdCardSolid,
  faPlusCircle,
  faSquareFull,
  faStar as faStarSolid,
  faTimes as faTimesSolid,
  faStickyNote as faStickyNoteSolid,
  faPhone as faPhoneSolid,
  faMobileAlt as faMobileAltSolid,
  faFolder as faFolderSolid,
  faEnvelope as faEnvelopeSolid,
  faAddressBook as faAddressBookSolid,
  faBook as faBookSolid,
  faHeart as faHeartSolid,
  faReply as faReplySolid,
  faRandom as faRandomSolid,
  faComments as faCommentsSolid,
  faHashtag as faHashtagSolid,
  faScroll as faScrollSolid,
  faPaperPlane as faPaperPlaneSolid,
  faFlag as faFlagSolid,
  faFile as faFileSolid,
  faLightbulb as faLightbulbSolid,
  faCompass as faCompassSolid,
  faHandshake as faHandshakeSolid,
  faCaretSquareRight as faCaretSquareRightSolid,
  faThumbsUp as faThumbsUpSolid,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faBars,
  faBooks,
  faCheck,
  faClipboard,
  faEdit,
  faEllipsisV,
  faFolder,
  faHashtag,
  faIdCard,
  faLongArrowLeft,
  faPaperPlane,
  faPlus,
  faScroll,
  faSearch,
  faBoltLight,
  faClipboardLight,
  faCog,
  faCopy,
  faEditLight,
  faRocket,
  faSave,
  faSignOutAlt,
  faStar,
  faTimes,
  faTrash,
  faUserAlt,
  faUsers,
  faBolt,
  faBoltRegular,
  faCheckSolid,
  faCheckSquare,
  faComment,
  faFlag,
  faFile,
  faLightbulb,
  faCompass,
  faHandshake,
  faCaretSquareRight,
  faPaperPlane,
  faThumbsUp,
  faUserEdit,
  faEditSolid,
  faGripVertical,
  faIdCardSolid,
  faPlusCircle,
  faSquareFull,
  faStarSolid,
  faTimesSolid,
  faStickyNote,
  faPhone,
  faMobileAlt,
  faFolderSolid,
  faEnvelope,
  faAddressBook,
  faBook,
  faHeart,
  faReply,
  faRandom,
  faComments,
  faStickyNoteSolid,
  faPhoneSolid,
  faMobileAltSolid,
  faFolderSolid,
  faEnvelopeSolid,
  faAddressBookSolid,
  faBookSolid,
  faHeartSolid,
  faReplySolid,
  faRandomSolid,
  faCommentsSolid,
  faHashtagSolid,
  faScrollSolid,
  faPaperPlaneSolid,
  faFlagSolid,
  faFileSolid,
  faLightbulbSolid,
  faCompassSolid,
  faHandshakeSolid,
  faCaretSquareRightSolid,
  faPaperPlaneSolid,
  faThumbsUpSolid,
)

dom.watch()
