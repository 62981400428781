import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://56f3f31425b9448f9d4981ce5daba5fd@o427276.ingest.sentry.io/5550534",
  environment: SalesKit.environment,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (SalesKit.currentUserId) {
  Sentry.setUser({
    id: SalesKit.currentUserId,
    email: SalesKit.currentUserEmail,
    organizationId: SalesKit.currentUserOrganizationId,
  })
}
